import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'nex-header',
  standalone: true,
  imports: [CommonModule, RouterLink, FormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [],
})
export class HeaderComponent implements AfterViewInit {
  isShown = true;
  isActive = false;
  isDarkMode: boolean = true;
  isInitialized: boolean = false;
  isNavbarGlowing = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadThemePreference();
      this.applyTheme();
    }
  }

  saveThemePreference() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('isDarkMode', JSON.stringify(this.isDarkMode));
    }
  }

  loadThemePreference() {
    if (isPlatformBrowser(this.platformId)) {
      const savedThemePreference = localStorage.getItem('isDarkMode');
      if (savedThemePreference !== null) {
        this.isDarkMode = JSON.parse(savedThemePreference);
        // this.applyTheme();
      }
    }
  }

  toggleTheme() {
    this.isInitialized = true;
    this.isDarkMode = !this.isDarkMode;
    this.applyTheme();
    this.saveThemePreference();
  }

  applyTheme() {
    const body = document.body;
    const themeToggle = this.el.nativeElement.querySelector('.theme-toggle');
    if (this.isDarkMode) {
      body.classList.add('dark-mode');
      body.classList.remove('light-mode');
      this.renderer.addClass(themeToggle, 'dark-mode');
      this.renderer.removeClass(themeToggle, 'light-mode');
    } else {
      body.classList.add('light-mode');
      body.classList.remove('dark-mode');
      this.renderer.addClass(themeToggle, 'light-mode');
      this.renderer.removeClass(themeToggle, 'dark-mode');
    }
    if (this.isInitialized) {
      body.classList.add('theme-transition');
    }
  }
  closeMenu() {
    if (!this.isShown) {
      setTimeout(() => {
        this.isShown = !this.isShown;
      }, 250);
      this.isActive = true;
    } else {
      this.isActive = false;
      this.isShown = !this.isShown;
    }
  }
  @HostListener('document:scroll', ['$event'])
  onDocumentScroll(event: Event) {
    this.checkScrollPosition();
  }

  private checkScrollPosition() {
    const scrollPosition = this.viewportScroller.getScrollPosition()[1];
    this.isNavbarGlowing = scrollPosition > 100;
  }
}
