import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'nex-cookies',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss',
})
export class CookiesComponent {
  hideClass: boolean = false;

  cookieHide() {
    if (!this.hideClass) {
      this.hideClass = true;
    }
  }
}
