<!-- Menu start -->
<div *ngIf="!isShown" class="container-fluid px-0">
  <nav
    class="menu-header fixed-top px-0 px-sm-5 ps-3 d-flex justify-content-between align-items-center"
  >
    <a class="navbar-brand me-0" routerLink="/" (click)="closeMenu()"
      ><div *ngIf="isDarkMode">
        <img src="../../../assets/icons/nexotips-r.png" alt="Nexotips-Logo" />
      </div>
      <div *ngIf="!isDarkMode">
        <img
          src="../../../assets/icons/nexotips-white.png"
          alt="Nexotips-Logo"
        /></div
    ></a>
    <div class="d-flex column-gap-2 column-gap-md-3 flex-row">
      <div class="theme-toggle" (click)="toggleTheme()">
        <i [class]="isDarkMode ? 'bi bi-moon-fill' : 'bi bi-sun-fill'"></i>
      </div>
      <div class="menubar">
        <button
          type="button"
          class="btn-close w-5"
          (click)="closeMenu()"
        ></button>
      </div>
    </div>
  </nav>
  <div class="menu-body fixed-top animate__animated animate__fadeIn">
    <div class="menu d-flex ps-4 ps-md-0 position-relative">
      <div
        class="container d-flex flex-column justify-content-center align-items-center gap-xl-4 gap-3 m-0 m-md-auto"
      >
        <div
          class="row d-flex flex-column flex-md-row gap-xl-4 gap-3 menu-top-row"
        >
          <div
            (click)="closeMenu()"
            routerLink="about"
            class="col menu-top-col-left animate__animated animate__fadeInTopLeft"
            [ngClass]="{
              'animate__animated animate__fadeOutTopLeft': isActive
            }"
          >
            <a>
              <h1>About</h1>
              <p class="d-none d-md-block">Empowering brands</p>
            </a>
          </div>
          <div
            (click)="closeMenu()"
            routerLink="projects"
            class="col menu-top-col-right animate__animated animate__fadeInTopRight"
            [ngClass]="{
              'animate__animated animate__fadeOutTopRight': isActive
            }"
          >
            <a>
              <h1>Projects</h1>
              <p class="d-none d-md-block">Case Studies</p>
            </a>
          </div>
        </div>
        <div class="row menu-middle-row">
          <div
            (click)="closeMenu()"
            routerLink="services"
            class="col menu-middle-col animate__animated animate__fadeInLeft"
            [ngClass]="{
              'animate__animated animate__fadeOutLeft': isActive
            }"
          >
            <a>
              <h1>Services</h1>
              <p class="d-none d-md-block">Areas of Expertise</p>
            </a>
          </div>
        </div>
        <div class="row gap-xl-4 gap-3 menu-bottom-row flex-column flex-md-row">
          <div
            (click)="closeMenu()"
            routerLink="career"
            class="col menu-bottom-col-left animate__animated animate__fadeInBottomLeft"
            [ngClass]="{
              'animate__animated animate__fadeOutBottomLeft': isActive
            }"
          >
            <a>
              <h1>Careers</h1>
              <p class="d-none d-md-block">Join our journey</p>
            </a>
          </div>
          <div
            (click)="closeMenu()"
            routerLink="contact-us"
            class="col menu-bottom-col-right animate__animated animate__fadeInBottomRight"
            [ngClass]="{
              'animate__animated animate__fadeOutBottomRight': isActive
            }"
          >
            <a>
              <h1>Contact Us</h1>
              <p class="d-none d-md-block">Partner with us</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="m-auto bottom-0 position-fixed d-flex justify-content-center w-full menu-copyright animate__animated animate__fadeIn"
  >
    <div class="copyright">
      © Copyright <strong>Nexotips</strong>. All Rights Reserved. Terms of
      Service and Privacy Policy.
    </div>
  </div>
</div>
<!-- Menu end -->
<!-- Header start -->
<header>
  <div *ngIf="isShown">
    <nav
      class="navbar fixed-top navbar-dark"
      [ngClass]="{ glow: isNavbarGlowing }"
    >
      <div class="container-fluid px-0 px-sm-5 ps-3">
        <a class="navbar-brand me-0" routerLink="/"
          ><div *ngIf="isDarkMode">
            <img
              src="../../../assets/icons/nexotips-r.png"
              alt="Nexotips-Logo"
            />
          </div>
          <div *ngIf="!isDarkMode">
            <img
              src="../../../assets/icons/nexotips-white.png"
              alt="Nexotips-Logo"
            /></div
        ></a>
        <div class="d-flex column-gap-2 column-gap-md-3 flex-row">
          <div class="theme-toggle" (click)="toggleTheme()">
            <i [class]="isDarkMode ? 'bi bi-moon-fill' : 'bi bi-sun-fill'"></i>
          </div>
          <div class="menubar">
            <button class="navbar-toggler" type="button" (click)="closeMenu()">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
<!-- Header end -->
