import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((mod) => mod.HomeComponent),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./pages/about/about.component').then((mod) => mod.AboutComponent),
  },
  {
    path: 'services',
    loadComponent: () =>
      import('./pages/services/services/services.component').then(
        (mod) => mod.ServicesComponent
      ),
  },
  {
    path: 'web-development-services',
    loadComponent: () =>
      import(
        './pages/services/services-list/web-development/web-development.component'
      ).then((mod) => mod.WebDevelopmentComponent),
  },
  {
    path: 'career',
    loadComponent: () =>
      import('./pages/career/career.component').then(
        (mod) => mod.CareerComponent
      ),
  },
  {
    path: 'requirement',
    loadComponent: () =>
      import('./pages/requirement-intake/requirement-intake.component').then(
        (mod) => mod.RequirementIntakeComponent
      ),
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./pages/contact-us/contact-us.component').then(
        (mod) => mod.ContactUsComponent
      ),
  },
  {
    path: 'projects',
    loadComponent: () =>
      import('./pages/projects/projects.component').then(
        (mod) => mod.ProjectsComponent
      ),
  },
  {
    path: 'projects/:id',
    loadComponent: () =>
      import('./pages/projects/project-detail/project-detail.component').then(
        (mod) => mod.ProjectDetailComponent
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-policy/privacy-policy.component').then(
        (mod) => mod.PrivacyPolicyComponent
      ),
  },
  { path: '**', component: PageNotFoundComponent },
];
