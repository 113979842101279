import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import VanillaTilt from 'vanilla-tilt';

@Component({
  selector: 'nex-page-not-found',
  standalone: true,
  imports: [],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
})
export class PageNotFoundComponent implements AfterViewInit {
  @ViewChild('tiltImage', { static: true }) tiltImage: ElementRef | undefined;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      VanillaTilt.init(this.tiltImage?.nativeElement, {
        max: 10,
        speed: 200,
      });
    }
  }
}
