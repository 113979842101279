import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  NavigationEnd,
  RouterLink,
  RouterOutlet,
  Router,
} from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectService } from './core/service/project.service';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { WorkWithUsBtnComponent } from './pages/work-with-us-btn/work-with-us-btn.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    CommonModule,
    HttpClientModule,
    RouterLink,
    // NgxIntlTelInputModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    CookiesComponent,
    WorkWithUsBtnComponent,
  ],
  providers: [ProjectService],
})
export class AppComponent {
  title = 'Nexotips-webapp';

  showFooter: boolean = true;
  showBtn: boolean = true;

  constructor(private router: Router) {
    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url == '/projects') {
          this.showFooter = false;
        } else if (value.url == '/requirement') {
          this.showBtn = false;
        } else {
          this.showFooter = true;
          this.showBtn = true;
        }
      }
    });
  }
}
